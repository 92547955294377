import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>Sobre mim</h4>
                            </div>
                            <h3>Sou um desenvolvedor back-end que trabalha de casa.</h3>
                            <div className="separator" />
                            <p>Possuo ótimas experiências em Desenvolvimento back-end, atuando no levantamento de necessidades de clientes, apresentação de soluções focadas em performance, escalabilidade e satisfação de usuários.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.github.com/lucasoliveira08')}/>
                                <FontAwesomeIcon icon={faInstagram} className="social_icon" onClick={() => window.open('https://www.instagram.com/lucasgeisel')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/lucasgoliveira')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>Minhas skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Node.js (Javascript & Typescript)" value={100} delay={1100} />
                                <Progress name="Go" value={100} delay={1100} />
                                <Progress name="C# (.NET)" value={100} delay={1100} />
                                <Progress name="PHP (Laravel)" value={100} delay={1100} />
                                <Progress name="AWS" value={100} delay={1100} />
                                <Progress name="Azure" value={100} delay={1100} />
                                <Progress name="Serverless" value={100} delay={1100} />
                                <Progress name="SQL" value={100} delay={1100} />
                                <Progress name="Metodologias ágeis" value={100} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero